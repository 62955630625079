<template>
    <layout-auth>
        <div class="px-138">
            <div class="my-96">
              <img src="@/assets/images/logo/dashboard-logo.svg" alt="">
            </div>
            <app-heading-1 class="">Verify Email</app-heading-1>
            <app-normal-text class="">We sent a verification code to your email address. Enter the code from the email in the field below.</app-normal-text>
            <app-normal-text class="mb-2 font-aeonik-bold"><b>{{getSimplifiedEmail}}</b></app-normal-text>
            <b-alert variant="danger" :show="errorMessage.length" class="p-1">{{errorMessage}}</b-alert>
            <div class="row">
                <div class="col-12 mb-2px">
                    <app-normal-text class="font-aeonik-bold">Type your 6 digit security code</app-normal-text>
                </div>
                <div class="col-12 d-flex mb-16">
                    <b-form-input type="text" :maxlength="1"  size="lg" v-model="otp.d1" :class="errorMessage.length?'border-danger':''" @keyup="e=>e.target.nextElementSibling.focus()" @keyup.delete="e=>e.target.focus()" class="rounded-3 p-0 text-center font-aeonik-bold" />
                    <b-form-input type="text" :maxlength="1"  size="lg" v-model="otp.d2" :class="errorMessage.length?'border-danger':''" @keyup="e=>e.target.nextElementSibling.focus()" @keyup.delete="e=>e.target.previousElementSibling.focus()"  class="ml-1 rounded-3  p-0 text-center font-aeonik-bold"/>
                    <b-form-input type="text" :maxlength="1"  size="lg" v-model="otp.d3" :class="errorMessage.length?'border-danger':''" @keyup="e=>e.target.nextElementSibling.focus()" @keyup.delete="e=>e.target.previousElementSibling.focus()"  class="ml-1 rounded-3  p-0 text-center font-aeonik-bold"/>
                    <b-form-input type="text" :maxlength="1"  size="lg" v-model="otp.d4" :class="errorMessage.length?'border-danger':''" @keyup="e=>e.target.nextElementSibling.focus()" @keyup.delete="e=>e.target.previousElementSibling.focus()"  class="ml-1 rounded-3  p-0 text-center font-aeonik-bold"/>
                    <b-form-input type="text" :maxlength="1"  size="lg" v-model="otp.d5" :class="errorMessage.length?'border-danger':''" @keyup="e=>e.target.nextElementSibling.focus()" @keyup.delete="e=>e.target.previousElementSibling.focus()"  class="ml-1 rounded-3  p-0 text-center font-aeonik-bold"/>
                    <b-form-input type="text" :maxlength="1"  size="lg" v-model="otp.d6" :class="errorMessage.length?'border-danger':''" @keyup="e=>e.target.focus()" @keyup.delete="e=>e.target.previousElementSibling.focus()" class="ml-1 rounded-3 text-center  p-0 font-aeonik-bold"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <app-simple-button variant="primary" :disabled="isBtnActive" :loading="loading" class="mb-16" @click="verifyOtp">Verify my account</app-simple-button>
                    <app-normal-text class="my-auto">
                      Didn't get the code? <b-spinner variant="primary" label="Spinning" v-if="resendLoading"></b-spinner><b-link v-else-if="!resendLoading && count=='0:00'" @click="resendOtp">Resend</b-link><span v-else class="font-aeonik-bold opacity-50"> Resend - {{count}}</span>
                    </app-normal-text>
                </div>
            </div>
        </div>    
    </layout-auth>        
</template>
<script>
import LayoutAuth from '@/layouts/layout-auth.vue'
import AppHeading1 from '@/components/app-components/AppHeading1.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import { BFormInput, BLink, BAlert, BSpinner } from 'bootstrap-vue'
import {verify_otp, resend_otp} from '@/apis/admin/auth'
export default{
components: {
LayoutAuth,
AppHeading1,
AppNormalText,
AppTextInput,
AppSimpleButton,
BFormInput,
BLink,
BAlert,
BSpinner
},
data(){
return {
    otp:{
        d1:'',
        d2:'',
        d3:'',
        d4:'',
        d5:'',
        d6:'',
    },
    count:'1:00',
    loading:false,
    errorMessage:'',
    resendLoading:false
}
},
computed: {
getSimplifiedEmail(){
    let email = this.$route.query.email;
    let match= email.match(/^([^@]+)@(.+)$/)
    let username= match[1];
    let domain= match[2];
    return username.length>4?`${username.slice(0,4)}****@${domain}`:email;
},
isBtnActive(){
    return Object.values(this.otp).join('').length<6?true:false;
}
},
mounted(){    
this.startCountDown();
if(this.$route.query.notVerified){
    this.resendOtp();
}
},
methods:{
verifyOtp(){
    this.loading=true
    this.errorMessage=''
   let data={
        email:this.$route.query.email,
        otpToken:Object.values(this.otp).join(''),
    }
    verify_otp(data).then(resp=>{
        this.loading=false
        this.$bvToast.toast(resp.message, {
          title: 'Email verified',
          variant: 'success',
          solid: true,
        })
        localStorage.setItem('accessToken', resp.data.accessToken);
        localStorage.setItem('refreshToken', resp.data.refreshToken);
        localStorage.setItem('user', JSON.stringify(resp.data.user));
        setTimeout(()=>{this.$router.replace('/admin/dashboard')},[1000])
    }).catch(error=>{
        this.loading=false
        this.errorMessage=error.message
        console.log(error)
    })
    // console.log("get data from meta:", this.$route.meta)
},
startCountDown(){
    clearInterval(interval);
    let interval= setInterval(()=>{
        let timer=this.count;
        timer=timer.split(':');
        let minutes=timer[0];
        let seconds=timer[1];
        seconds -= 1;
        if (minutes < 0) return;
        else if (seconds < 0 && minutes != 0) {
            minutes = 0;
            seconds = 59;
        }
        else if (seconds < 10 && length.seconds != 2) seconds = '0' + seconds;
        this.count=`${minutes}:${seconds}`
        if (minutes == 0 && seconds == 0) 
        clearInterval(interval);
    },1000)
},
resendOtp(){
    this.errorMessage='';
    this.resendLoading=true
    resend_otp(this.$route.query.email).then(resp=>{
        this.resendLoading=false
        this.count='0:59'
        this.startCountDown();
        this.$bvToast.toast(resp.message, {
          title: 'Email verified',
          variant: 'success',
          solid: true,
        })
    }).catch(e=>{
        this.resendLoading=false
        this.errorMessage=e.message
    })
},

}
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

</style>